import { Routes } from '@angular/router';

export const FAQ_ROUTES: Routes = [
  {
    path: 'faqs_ferienlagerangebote/kategorie/:category',
    loadComponent: () =>
      import('./views/faq-categories-page/faq-categories-page.component').then(
        (m) => m.FaqCategoriesPageComponent,
      ),
  },
  {
    path: 'faq/:name',
    loadComponent: () =>
      import('./views/faq-single-page/faq-single-page.component').then(
        (m) => m.FaqSinglePageComponent,
      ),
  },
  {
    path: 'faqs_ferienlagerangebote',
    loadComponent: () =>
      import('./views/faq-overview-page/faq-overview-page.component').then(
        (m) => m.FaqOverviewPageComponent,
      ),
  },
  {
    path: 'faqs_ferienlagerangebote/suche/:query',
    loadComponent: () =>
      import('./views/faq-search-results-page/faq-search-results-page.component').then(
        (m) => m.FaqSearchResultsPageComponent,
      ),
  },
];
