/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteFaqRelationRemove } from '../fn/faq/delete-faq-relation-remove';
import { DeleteFaqRelationRemove$Params } from '../fn/faq/delete-faq-relation-remove';
import { deleteFaqRemove } from '../fn/faq/delete-faq-remove';
import { DeleteFaqRemove$Params } from '../fn/faq/delete-faq-remove';
import { FaqPaginateResponse } from '../models/faq-paginate-response';
import { FaqShowResponse } from '../models/faq-show-response';
import { getFaqIndex } from '../fn/faq/get-faq-index';
import { GetFaqIndex$Params } from '../fn/faq/get-faq-index';
import { getFaqShow } from '../fn/faq/get-faq-show';
import { GetFaqShow$Params } from '../fn/faq/get-faq-show';
import { patchFaqUpdate } from '../fn/faq/patch-faq-update';
import { PatchFaqUpdate$Params } from '../fn/faq/patch-faq-update';
import { postFaqCreate } from '../fn/faq/post-faq-create';
import { PostFaqCreate$Params } from '../fn/faq/post-faq-create';
import { postFaqRelationCreate } from '../fn/faq/post-faq-relation-create';
import { PostFaqRelationCreate$Params } from '../fn/faq/post-faq-relation-create';


/**
 * Faq
 */
@Injectable({ providedIn: 'root' })
export class FaqService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getFaqIndex()` */
  static readonly GetFaqIndexPath = '/v1/faq';

  /**
   * list faqs.
   *
   * Fetch paginated list of frequently asked questions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFaqIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqIndex$Response(params?: GetFaqIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqPaginateResponse>> {
    return getFaqIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list faqs.
   *
   * Fetch paginated list of frequently asked questions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFaqIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqIndex(params?: GetFaqIndex$Params, context?: HttpContext): Observable<FaqPaginateResponse> {
    return this.getFaqIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqPaginateResponse>): FaqPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postFaqCreate()` */
  static readonly PostFaqCreatePath = '/v1/faq';

  /**
   * add a new faq.
   *
   * create a new frequently asked question
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFaqCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postFaqCreate$Response(params: PostFaqCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqShowResponse>> {
    return postFaqCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * add a new faq.
   *
   * create a new frequently asked question
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postFaqCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postFaqCreate(params: PostFaqCreate$Params, context?: HttpContext): Observable<FaqShowResponse> {
    return this.postFaqCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqShowResponse>): FaqShowResponse => r.body)
    );
  }

  /** Path part for operation `getFaqShow()` */
  static readonly GetFaqShowPath = '/v1/faq/{name}';

  /**
   * show a faq.
   *
   * Fetch a single frequently asked question
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFaqShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqShow$Response(params: GetFaqShow$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqShowResponse>> {
    return getFaqShow(this.http, this.rootUrl, params, context);
  }

  /**
   * show a faq.
   *
   * Fetch a single frequently asked question
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFaqShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqShow(params: GetFaqShow$Params, context?: HttpContext): Observable<FaqShowResponse> {
    return this.getFaqShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqShowResponse>): FaqShowResponse => r.body)
    );
  }

  /** Path part for operation `deleteFaqRemove()` */
  static readonly DeleteFaqRemovePath = '/v1/faq/{name}';

  /**
   * remove a faq.
   *
   * Mark faq as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFaqRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFaqRemove$Response(params: DeleteFaqRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqShowResponse>> {
    return deleteFaqRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a faq.
   *
   * Mark faq as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFaqRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFaqRemove(params: DeleteFaqRemove$Params, context?: HttpContext): Observable<FaqShowResponse> {
    return this.deleteFaqRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqShowResponse>): FaqShowResponse => r.body)
    );
  }

  /** Path part for operation `patchFaqUpdate()` */
  static readonly PatchFaqUpdatePath = '/v1/faq/{name}';

  /**
   * update a faq.
   *
   * update frequently asked question
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchFaqUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchFaqUpdate$Response(params: PatchFaqUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqShowResponse>> {
    return patchFaqUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * update a faq.
   *
   * update frequently asked question
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchFaqUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchFaqUpdate(params: PatchFaqUpdate$Params, context?: HttpContext): Observable<FaqShowResponse> {
    return this.patchFaqUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqShowResponse>): FaqShowResponse => r.body)
    );
  }

  /** Path part for operation `postFaqRelationCreate()` */
  static readonly PostFaqRelationCreatePath = '/v1/faq-relation';

  /**
   * create a faq relation.
   *
   * mark a faq as related to another faq
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFaqRelationCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postFaqRelationCreate$Response(params: PostFaqRelationCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postFaqRelationCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * create a faq relation.
   *
   * mark a faq as related to another faq
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postFaqRelationCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postFaqRelationCreate(params: PostFaqRelationCreate$Params, context?: HttpContext): Observable<void> {
    return this.postFaqRelationCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteFaqRelationRemove()` */
  static readonly DeleteFaqRelationRemovePath = '/v1/faq-relation';

  /**
   * remove a faq relation.
   *
   * remove one faq from relations of another
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFaqRelationRemove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteFaqRelationRemove$Response(params: DeleteFaqRelationRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteFaqRelationRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a faq relation.
   *
   * remove one faq from relations of another
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFaqRelationRemove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteFaqRelationRemove(params: DeleteFaqRelationRemove$Params, context?: HttpContext): Observable<void> {
    return this.deleteFaqRelationRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
