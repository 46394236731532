/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteFaqCategoryRemove } from '../fn/faq-category/delete-faq-category-remove';
import { DeleteFaqCategoryRemove$Params } from '../fn/faq-category/delete-faq-category-remove';
import { FaqCategoryPaginateResponse } from '../models/faq-category-paginate-response';
import { FaqCategoryShowResponse } from '../models/faq-category-show-response';
import { getFaqCategoryIndex } from '../fn/faq-category/get-faq-category-index';
import { GetFaqCategoryIndex$Params } from '../fn/faq-category/get-faq-category-index';
import { getFaqCategoryShow } from '../fn/faq-category/get-faq-category-show';
import { GetFaqCategoryShow$Params } from '../fn/faq-category/get-faq-category-show';
import { patchFaqCategoryUpdate } from '../fn/faq-category/patch-faq-category-update';
import { PatchFaqCategoryUpdate$Params } from '../fn/faq-category/patch-faq-category-update';
import { postFaqCategoryCreate } from '../fn/faq-category/post-faq-category-create';
import { PostFaqCategoryCreate$Params } from '../fn/faq-category/post-faq-category-create';


/**
 * FaqCategory
 */
@Injectable({ providedIn: 'root' })
export class FaqCategoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getFaqCategoryIndex()` */
  static readonly GetFaqCategoryIndexPath = '/v1/faq-category';

  /**
   * list faq-categories.
   *
   * Fetch paginated list of frequently asked question categories
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFaqCategoryIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqCategoryIndex$Response(params?: GetFaqCategoryIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqCategoryPaginateResponse>> {
    return getFaqCategoryIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list faq-categories.
   *
   * Fetch paginated list of frequently asked question categories
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFaqCategoryIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqCategoryIndex(params?: GetFaqCategoryIndex$Params, context?: HttpContext): Observable<FaqCategoryPaginateResponse> {
    return this.getFaqCategoryIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqCategoryPaginateResponse>): FaqCategoryPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postFaqCategoryCreate()` */
  static readonly PostFaqCategoryCreatePath = '/v1/faq-category';

  /**
   * add a new faq-category.
   *
   * create a new frequently asked question
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFaqCategoryCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postFaqCategoryCreate$Response(params: PostFaqCategoryCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqCategoryShowResponse>> {
    return postFaqCategoryCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * add a new faq-category.
   *
   * create a new frequently asked question
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postFaqCategoryCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postFaqCategoryCreate(params: PostFaqCategoryCreate$Params, context?: HttpContext): Observable<FaqCategoryShowResponse> {
    return this.postFaqCategoryCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqCategoryShowResponse>): FaqCategoryShowResponse => r.body)
    );
  }

  /** Path part for operation `getFaqCategoryShow()` */
  static readonly GetFaqCategoryShowPath = '/v1/faq-category/{name}';

  /**
   * show a faq-category.
   *
   * Fetch a single frequently asked question
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFaqCategoryShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqCategoryShow$Response(params: GetFaqCategoryShow$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqCategoryShowResponse>> {
    return getFaqCategoryShow(this.http, this.rootUrl, params, context);
  }

  /**
   * show a faq-category.
   *
   * Fetch a single frequently asked question
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFaqCategoryShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaqCategoryShow(params: GetFaqCategoryShow$Params, context?: HttpContext): Observable<FaqCategoryShowResponse> {
    return this.getFaqCategoryShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqCategoryShowResponse>): FaqCategoryShowResponse => r.body)
    );
  }

  /** Path part for operation `deleteFaqCategoryRemove()` */
  static readonly DeleteFaqCategoryRemovePath = '/v1/faq-category/{name}';

  /**
   * remove a faq.
   *
   * Mark faq as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFaqCategoryRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFaqCategoryRemove$Response(params: DeleteFaqCategoryRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqCategoryShowResponse>> {
    return deleteFaqCategoryRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a faq.
   *
   * Mark faq as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFaqCategoryRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFaqCategoryRemove(params: DeleteFaqCategoryRemove$Params, context?: HttpContext): Observable<FaqCategoryShowResponse> {
    return this.deleteFaqCategoryRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqCategoryShowResponse>): FaqCategoryShowResponse => r.body)
    );
  }

  /** Path part for operation `patchFaqCategoryUpdate()` */
  static readonly PatchFaqCategoryUpdatePath = '/v1/faq-category/{name}';

  /**
   * update a faq.
   *
   * update frequently asked question
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchFaqCategoryUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchFaqCategoryUpdate$Response(params: PatchFaqCategoryUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqCategoryShowResponse>> {
    return patchFaqCategoryUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * update a faq.
   *
   * update frequently asked question
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchFaqCategoryUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchFaqCategoryUpdate(params: PatchFaqCategoryUpdate$Params, context?: HttpContext): Observable<FaqCategoryShowResponse> {
    return this.patchFaqCategoryUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqCategoryShowResponse>): FaqCategoryShowResponse => r.body)
    );
  }

}
